<template>
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mb-3">
                    <button @click="selcetmenu" type="button" :class="dashboards?'btn btn-primary text-white btn-lg me-1': 'btn btn-lg me-1 tombol-disable'">Dashboard</button>
                    <button @click="selcetmenu2" type="button" :class="!dashboards?'btn btn-primary text-white btn-lg me-1': 'btn btn-lg me-1 tombol-disable'">Penjualan Barang</button>
                </div>
            </div>
        </div>
        <div v-if="dashboards" class="container-fluid">

            <div class="row">

                <div class="col-12 ">
                    <h2 class="text-primary">Dashboard</h2>
                </div>

                <div class="row g-2 mb-4">
                    

                    <div class="col-6 col-lg-5">
                        <div class="app-card app-card-stat shadow-sm h-100">
                            <div class="app-card-body p-3 p-lg-4">
                                <h4 class="stats-type mb-1">Transaksi Hari Ini</h4>
                                <div class="stats-figure">
                                    <div v-if="loading" class="comment br animate"></div>
                                    <div v-else>{{jumtransaksi}}</div>
                                </div>

                                <div class="stats-meta text-success">
                                </div>
                            </div>
                            <!--//app-card-body-->
                            <a class="app-card-link-mask" href="#"></a>
                        </div>
                        <!--//app-card-->
                    </div>
                    <!--//col-->
                    <div class="col-6 col-lg-7">
                        <div class="app-card app-card-stat shadow-sm h-100">
                            <div class="app-card-body p-3 p-lg-4">
                                <h4 class="stats-type mb-1">Pemasukan Hari Ini</h4>
                                <div class="stats-figure">
                                    <div v-if="loading" class="comment br animate"></div>
                                    <div v-else>{{ "Rp " + jumpendapatan.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</div>
                                </div>
                                <div class="stats-meta">
                                </div>
                            </div>
                            <!--//app-card-body-->
                            <a class="app-card-link-mask" href="#"></a>
                        </div>
                        <!--//app-card-->
                    </div>
                </div>
                <!--//row-->
                <div class="row mb-1">
                    <div class="col-12 col-md-8 pt-2 pb-3 pb-md-0">

                        <a class=" " style="position: relative;background: #fff;color:black;padding: .375rem .75rem;font-weight: 600;font-size: 1rem;line-height: 1.5;padding-top: .6rem;padding-bottom: .6rem;height: 2.5rem;border-radius: .25rem;">Data Checkout Barang</a>

                    </div>
                    <div class="col-12 col-md-4">
                        <input v-model="invoice" @change="getResults2" type="text" class="form-control my-input" id="inputPassword2" placeholder="Cari Nama Pembeli / Invoice">
                    </div>

                    <div :class="edit2.length != 0 ? 'col-12 col-md-5 mt-2 mb-2' : 'col-12  mt-2 mb-2'">
                        <div class="app-card app-card-orders-table shadow-sm  pt-2 pb-2 " style="width:100%">
                            <div class="app-card-body">
                                <div class="table-responsive p-2">
                                    <table class="table app-table-hover mb-0 text-center">
                                        <thead>
                                            <tr>
                                                <th class="cell">No</th>
                                                <th class="cell">Invoice</th>
                                                <th class="cell">Nama Pembeli</th>
                                                <th class="cell">Total</th>
                                                <th class="cell">Aksi</th>
                                            </tr>
                                        </thead>

                                        <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                            <tr v-for="(post2, index) in posts2" :key="index">
                                                <td class="cell">{{ index +1 }}</td>
                                                <td class="cell">{{ post2.invoice  }}</td>
                                                <td class="cell">{{ post2.nama_pembeli  }}</td>
                                                <td class="cell">{{"Rp " + total2(index).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>

                                                <td class="text-center cell">
                                                    <!-- Button trigger modal -->

                                                    <button type="button" class="btn btn-primary text-white btn-sm me-2" @click="setEdit2(index)">
                                                        Pilih
                                                    </button>

                                                </td>
                                            </tr>
                                            <tr v-if="posts2.length == 0">
                                                <td class="cell" colspan="5">
                                                    <h4>Kosong</h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody style="border-top: 1px solid grey" v-else>
                                            <tr>
                                                <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                    <h3> Loading....</h3>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav class="app-pagination mt-3">
                                        <ul class="pagination justify-content-center">

                                            <li :class="[pagination2.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks2(pagination2.prev_page_url)">Previous</a></li>
                                            <li v-if="pagination2.current_page !=1 && pagination2.current_page !=2" :class="[pagination2.current_page == 1 ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick2(1)">1</a></li>
                                            <li :class="[pagination2.current_page == item ?  'active page-item'  : 'page-item'] " v-for="item =1 in pagination2.last_page" :key="item">
                                                <a class="page-link" v-if="Math.abs(item -1 - pagination2.current_page) < 3 || item -1 == pagination2.total - 1 " href="#" @click="onClick2(item)" :class="{current: pagination2.current_page === item-1, last: (item -1== pagination2.total - 1 && Math.abs(item -1- pagination2.current_page) > 3), first:(item -1== 0 && Math.abs(item -1- pagination2.current_page) > 3)}">{{ item+1-1 }}</a>
                                            </li>
                                            <li v-if="pagination2.current_page !=pagination2.last_page && pagination2.current_page <pagination2.last_page-5 " :class="[pagination2.current_page == pagination2.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick2(pagination2.last_page)">{{pagination2.last_page}}</a></li>

                                            <li class="page-item" :class="[pagination2.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks2(pagination2.next_page_url)">Next</a></li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="edit2.length !=0" class="col-12 col-md-7 mt-2 mb-2">
                        <div class="app-card app-card-orders-table shadow-sm mb-5 pt-2 pb-2 " style="height:100%;width:100%">
                            <div class="app-card-body p-2">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4>Detail Transaksi</h4>
                                    </div>
                                    <div class="col-12 col-md-6 d-sm-none d-md-block text-end">
                                        <i style=" cursor: pointer;" class='bx bx-x-circle bx-md' @click="clearedit"></i>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 ">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th style="font-size:14px" scope="row">Invoice</th>
                                                    <td style="font-size:14px">:</td>
                                                    <td style="font-size:14px">{{edit2.invoice}}</td>
                                                </tr>
                                                <tr>
                                                    <th style="font-size:14px" scope="row">Nama Pembeli</th>
                                                    <td style="font-size:14px">:</td>
                                                    <td style="font-size:14px">{{edit2.nama_pembeli}}</td>
                                                </tr>
                                                <tr>
                                                    <th style="font-size:14px" scope="row">Cash</th>
                                                    <td style="font-size:14px">:</td>
                                                    <td>
                                                        <!-- {{metode_pembayaran}} -->
                                                        <select name="" id="" class="form-control" v-model="metode_pembayaran">
                                                            <option value="1" :selected="edit2.iscash == 1">Cash</option>
                                                            <option value="0" :selected="edit2.iscash == 0">Hutang</option>
                                                            <option value="2" :selected="edit2.iscash == 2">Debit</option>
                                                        </select>
                                                        
                                                        <!-- <span style="font-size:14px" class="badge " :class="edit2.iscash == 1 ? 'bg-primary' :edit2.iscash == 2? 'bg-primary': ' bg-danger' ">{{edit2.iscash == 1 ? 'Cash' :edit2.iscash == 2 ? 'Debit' : 'Hutang'}}</span> -->
                                                    </td>
                                                </tr>
                                                <tr v-if="metode_pembayaran==2">
                                                    <td style="font-size:14px"></td>
                                                    <td style="font-size:14px"></td>
                                                    <td >
                                                        <select id="inputCabang" v-model="bankDebit2" class="form-select form-control pt-1">
                                                            <option value="">Pilih Bank</option>
                                                            <option value="Bank BNI">Bank BNI</option>
                                                            <option value="Bank BRI">Bank BRI</option>
                                                            <option value="Bank BCA">Bank BCA</option>
                                                            <option value="Bank BCA">Bank MANDIRI</option>
                                                        </select>
                                                    </td>
                                                    
                                                </tr>
                                                <tr v-if="metode_pembayaran==2">
                                                    <td style="font-size:14px"></td>
                                                    <td style="font-size:14px"></td>
                                                    <td >
                                                         <input type="text" v-model="jenisDebit2" class="form-control" id="norekInput" placeholder="Jenis Debit (EDC/TF)">
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="cell">No</th>
                                                    <th class="cell">Nama Barang</th>
                                                    <th class="cell">Qty</th>
                                                    <th class="cell">Harga Satuan</th>
                                                    <th class="cell">Diskon</th>
                                                    <th class="cell">Harga Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(edit2, index) in edit2.detail_transaksi" :key="index">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{edit2.barang.nama_barang}}</td>

                                                    <td>{{edit2.qty}}
                                                        </td>
                                                    <td>{{"Rp " + parseInt(edit2.harga_fix2).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</td>
                                                    <td><input min="0" max="100" type="number" v-model="diskonarray[index]" @change="diskonFunction(index)" class="form-control" ></td>
                                                    <td>{{"Rp " +parseInt(edit2.qty* edit2.harga_fix2).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</td>

                                                </tr>

                                                <tr>
                                                    <th scope="row" colspan="3">Total</th>
                                                    <td>:</td>
                                                    <td colspan="2">{{"Rp " + parseInt(cektotal()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                                                </tr>
                                                <tr >
                                                    <th scope="row" colspan="3">Potongan</th>
                                                    <td>:</td>
                                                    <td colspan="2">
                                                        <div class="input-group ">
                                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                                            <input v-model="potonganhitung" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Potongan" @change="savednewpotongan" @input="savednewpotongan">

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="metode_pembayaran == 2">
                                                    <th scope="row" colspan="3" >Bayar EDC</th>
                                                    <td>:</td>
                                                    <td colspan="2"> 
                                                        <div class="input-group ">
                                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                                            <input v-model="nominalEDC" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search" @change="nominalrupiah" @input="nominalrupiah">
                                                        </div>
                                                        <!-- <input type="number" min="0" class="form-control my-nominal" id="my-nominal" v-model="nominalEDC" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" placeholder="Masukan Nominal" > -->
                                                        
                                                    </td>
                                                </tr>
                                                <tr v-if="metode_pembayaran == 1">
                                                    <th scope="row" colspan="3">Bayar</th>
                                                    <td>:</td>
                                                    <td colspan="2"> 
                                                        <div class="input-group ">
                                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                                            <input v-model="nominal" type="text" id="search-docs" name="searchdocs" class="form-control search-docs my-nominal" placeholder="Search" @change="nominalrupiah2" @input="nominalrupiah2">
                                                        </div>
                                                        <!-- <input type="number" min="0" class="form-control my-nominal" id="my-nominal" v-model="nominal" oninput="this.value = this.value.toString().split('.').join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.');" placeholder="Masukan Nominal" @keyup.enter="ctkfile"> -->
                                                    </td>
                                                </tr>
                                                <tr v-else-if="metode_pembayaran == 2">
                                                    <th scope="row" colspan="3">Bayar</th>
                                                    <td>:</td>
                                                    <td colspan="2"> 
                                                        <div class="input-group ">
                                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                                            <input v-model="nominal" type="text" id="search-docs" name="searchdocs" class="form-control search-docs my-nominal" placeholder="Search" disabled="1" @change="nominalrupiah2" @input="nominalrupiah2">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-else>
                                                    <th scope="row" colspan="3">Pembarayan Awal</th>
                                                    <td>:</td>
                                                    <td colspan="2"> 
                                                        <div class="input-group ">
                                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                                            <input v-model="nominal" type="text" id="search-docs" name="searchdocs" class="form-control search-docs my-nominal" placeholder="Search" @change="nominalrupiah2" @input="nominalrupiah2">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td v-if="metode_pembayaran" for="kembaliannominal" colspan="3">Kembalian </td>
                                                    <td v-else for="kembaliannominal" colspan="3">Kurang </td>
                                                    <td>:</td>
                                                    <td v-if="metode_pembayaran ==2" colspan="2">
                                                        Rp {{ ((parseInt(nominal.toString().split(".").join(''))+parseInt(nominalEDC.toString().split(".").join(''))+parseInt(potongan))-cektotal()).toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}
                                                    </td>
                                                    <td v-else colspan="2">
                                                        Rp {{ ((parseInt(nominal.toString().split(".").join(''))+parseInt(potongan))-cektotal()).toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <button class="btn  btn-primary text-white" @click="ctkfile">Print</button> -->
                                         <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout1" :disabled="metode_pembayaran==1 ?((parseInt(nominal.toString().split('.').join(''))+parseInt(potongan))-cektotal()) < 0 || isNaN((parseInt(nominal.toString().split('.').join('')+parseInt(potongan)))-cektotal())?  1 : 0 : metode_pembayaran==2 ? ((parseInt(nominal.toString().split('.').join(''))+parseInt(potongan)+parseInt(nominalEDC.toString().split('.').join('')))-cektotal()) < 0 || isNaN((parseInt(nominal.toString().split('.').join(''))+parseInt(potongan)+parseInt(nominalEDC.toString().split('.').join('')))-cektotal())?  1 : 0 :((parseInt(nominal.toString().split('.').join(''))+parseInt(potongan))-cektotal()) > 0 ? 1 : 0">Checkout</button>
                                        <!-- <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout1" :disabled="metode_pembayaran == 1 || metode_pembayaran ==2   ? kembalian() < 0 || isNaN(kembalian()) ?  1 : 0 :kembalian() > 0 || isNaN(kembalian()) ? 1 : 0">Proses</button> -->
                                        <!-- Modal -->
                                        <div class="modal fade" id="staticCheckout1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">

                                                    <div class="modal-body">

                                                        <div class="text-center">
                                                            <img :src="'/logo/tanya.png'" alt="Logo" style="width:50px;height:50px">
                                                        </div>
                                                        <br>
                                                        Apakah Anda Yakin Ingin Proses?
                                                        <br>
                                                        Nama Pelanggan : <a :class="edit2.nama_pembeli == '' ? 'text-danger' : ''">{{ edit2.nama_pembeli == '' ? 'Belum Ada' : edit2.nama_pembeli }}</a>
                                                        <br>
                                                        Total Harga : {{ 'Rp.'+cektotal().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')}}
                                                        <br>
                                                        Jenis Pembayaran : {{ metode_pembayaran == 1? 'Cash' : metode_pembayaran == 2? 'Debit ': 'Hutang' }}
                                                        <br>
                                                        Bayar : {{ 'Rp.'+parseInt(nominal.toString().split('.').join('')).toString().replace(/(\d)(?=(\d3)+(?:\.\d+)?$)/g, '$1\.')  }}
                                                        <br>
                                                        Potongan : {{  'Rp.'+parseInt(potongan).toString().replace(/(\d)(?=(\d3)+(?:\.\d+)?$)/g, '$1\.')  }}
                                                        <br>
                                                        <div v-if="metode_pembayaran ==2">
                                                        Bayar EDC/TF : {{ 'Rp.'+parseInt(nominalEDC.toString().split('.').join('')).toString().replace(/(\d)(?=(\d3)+(?:\.\d+)?$)/g, '$1\.')  }}
                                                        </div>
                                                        <div v-if="metode_pembayaran ==2">
                                                            {{ bankDebit2 }} - {{ jenisDebit2 }}
                                                        </div>
                                                        <div v-if=" metode_pembayaran ==2">
                                                            Kembalian : {{ ((parseInt(nominal.toString().split(".").join(''))+parseInt(nominalEDC.toString().split(".").join(''))+parseInt(potongan))-cektotal()).toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}
                                                        </div>
                                                        <div v-else-if="metode_pembayaran == 1 ">
                                                            Kembalian : {{ ((parseInt(nominal.toString().split(".").join(''))+parseInt(potongan))-cektotal()).toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}
                                                        
                                                        </div>
                                                        <div v-else>
                                                            Sisa Hutang : {{ 'Rp.'+kembalian().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')}}
                                                        </div>
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Nama Produk</th>
                                                                    <th scope="col">Qty</th>
                                                                    <th  scope="col" >Diskon</th>
                                                                    <th scope="col">Harga</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody v-if="edit2.detail_transaksi.length >0">
                                                                <tr v-for="(post, index) in edit2.detail_transaksi" :key="index">
                                                                    <th scope="row">{{ index+1 }}</th>
                                                                    <td>{{post.barang.nama_barang}}</td>
                                                                    <td style="font-size: 18px">
                                                                        {{post.qty }}
                                                                    </td>
                                                                    <td  >
                                                                        {{ diskonarray[index] }}
                                                                    </td>
                                                                    <td>{{'Rp'+(post.qty* (post.harga_fix *((100-diskonarray[index])/100) )).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody v-else>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <h4 class="text-center">
                                                                            Keranjang Kosong
                                                                        </h4>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <br>

                                                        <div class="text-end">
                                                            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal" >Tidak</button>
                                                            <button type="button" class="btn btn-primary text-white" data-bs-dismiss="modal" @click="ctkfile">Ya</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Button trigger modal -->
                                        <button type="button" class="btn btn-primary notifberhasil1" data-bs-toggle="modal" data-bs-target="#staticSuccess1" style="display:none">

                                        </button>

                                        <!-- Modal -->
                                        <div class="modal fade" id="staticSuccess1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">

                                                    <div class="modal-body">
                                                        <div class="text-center">
                                                            <h3> Transaksi Penjualan Berhasil</h3>
                                                            <br>
                                                            <img :src="'/logo/checked.png'" alt="Logo" style="width:50px;height:50px">
                                                            <br>
                                                            <br>
                                                            <button type="button" class="btn btn-secondary me-2"  @click="printnewfile(false)" data-bs-dismiss="modal">Kembali ke Dashboard</button>
                                                            <button type="button" class="btn btn-primary text-white"  @click="printnewfile(true)" data-bs-dismiss="modal">Print Transaksi</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            

        </div>
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="app-card app-card-orders-table shadow-sm mb-2 pt-3 pb-5 ps-2 pe-2">
                        <div class="app-card-body">
                            <div class="col-auto">
                                <div class="page-utilities">
                                    <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                                        <div class="col-auto">
                                            <div class="docs-search-form row gx-1 align-items-center">
                                                
                                                <div class="col-8 col-md-auto mt-2 mt-md-0">

                                                    <div class="input-group ">
                                                        <input v-on:keyup.enter="getSearchbarang" v-model="searchbarang" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search">
                                                        <button @click="getResetbarang" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-auto mt-2 mt-md-0">
                                                    <button @click="getSearchbarang" type="submit" class="btn app-btn-secondary w-100" style="height:40px">Search</button>
                                                </div>
                                            </div>

                                        </div>
                                        <!--//col-->

                                    </div>
                                    <!--//row-->
                                </div>
                                <!--//table-utilities-->
                            </div>
                            <div class="table-responsive m-2">
                                <table class="table app-table-hover mb-0 text-center">
                                    <thead>
                                        <tr>
                                            <th class="cell">No</th>
                                            <th class="cell">Nama Barang</th>
                                            <th class="cell">Kantor Cabang</th>
                                            <th class="cell">Kode Barcode</th>
                                            <th class="cell">Merek</th>
                                            <th class="cell">Harga</th>
                                            <th class="cell">Stok</th>
                                            <th class="cell">Aksi</th>
                                        </tr>
                                    </thead>

                                    <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                        <tr v-for="(postbarang, index) in postbarangs" :key="index">
                                            <td class="cell">{{ index +1 }}</td>
                                            <td class="cell">{{ postbarang.nama_barang }}</td>
                                            <td class="cell">{{ postbarang.kantor_cabang.nama_cabang }}</td>
                                            <td class="cell">
                                              {{ postbarang.kode_barcode }}

                                            </td>
                                            <td class="cell">{{ postbarang.merek}}</td>
                                            <td class="cell">
                                                <s style="color:red" v-if="postbarang.diskon!=0">{{ "Rp" + postbarang.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</s>
                                                {{ "Rp" + (postbarang.harga_cash-(postbarang.harga_cash*postbarang.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}
                                                <br>
                                                <s style="color:red" v-if="postbarang.diskon!=0">{{ "Rp" + postbarang.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</s>
                                                {{ "Rp" + (postbarang.harga_hutang-(postbarang.harga_hutang*postbarang.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}
                                            </td>
                                            <td class="cell">{{ postbarang.stok }}</td>
                                            <td class="text-center cell">
                                                <button class="btn btn-primary text-white" @click="tambahkeranjang(postbarang.id)">Tambah</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody style="border-top: 1px solid grey" v-else>
                                        <tr>
                                            <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                <h3> Loading....</h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <Pagination :data="laravelData" @pagination-change-page="getResults" /> -->

                                <nav class="app-pagination mt-3">
                                    <ul class="pagination justify-content-center">

                                        <li :class="[paginationbarang.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarangs(paginationbarang.prev_page_url)">Previous</a></li>
                                        <li v-if="paginationbarang.current_page !=1 && paginationbarang.current_page !=2" :class="[paginationbarang.current_page == 1 ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarang(1)" style=" cursor: pointer;">1</a></li>
                                        <li :class="[paginationbarang.current_page == item ?  'active page-item'  : 'page-item'] " v-for="item =1 in paginationbarang.last_page" :key="item">
                                            <a class="page-link" v-if="Math.abs(item -1 - paginationbarang.current_page) < 3 || item -1 == paginationbarang.total - 1 " href="#" @click="onClickbarang(item)" :class="{current: paginationbarang.current_page === item-1, last: (item -1== paginationbarang.total - 1 && Math.abs(item -1- paginationbarang.current_page) > 3), first:(item -1== 0 && Math.abs(item -1- paginationbarang.current_page) > 3)}">{{ item+1-1 }}</a>
                                        </li>
                                        <li v-if="paginationbarang.current_page !=paginationbarang.last_page && paginationbarang.current_page <paginationbarang.last_page-5 " :class="[paginationbarang.current_page == paginationbarang.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarang(paginationbarang.last_page)" style=" cursor: pointer;">{{paginationbarang.last_page}}</a></li>
                                        <!-- <div v-for="item =1 in pagination.last_page" :key="item">
                                                <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                            </div> -->
                                        <li class="page-item" :class="[paginationbarang.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarangs(paginationbarang.next_page_url)">Next</a></li>
                                    </ul>
                                </nav>

                                <!--//app-pagination-->

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="app-card app-card-orders-table shadow-sm mb-2 pt-3 pb-5 ps-2 pe-2">
                        <div class="app-card-body">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Nama Pembeli</label>
                                        <input type="text" v-model="namapembeli" class="form-control" id="namapembeliInput" placeholder="Masukan Nama Pembeli">
                                    </div>
                                </div>
                                <div class="col-0 col-md-2"></div>
                                <div class="col-12 col-md-4 text-center">
                                    <h4 class=" mb-1">Pembayaran</h4>

                                    <div class="d-flex justify-content-center">
                                       
                                            <!-- <input class="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="iscash2" @click="onofcash"> -->
                                            <div class="row">
                                                <div class="col-12">

                                                    <select id="inputCabang" v-model="iscash2" class="form-select form-control pt-1" @change="onofcash">
                                                              <option value="1">Cash</option>
                                                              <option value="2">Debit</option>
                                                              <option value="0">Hutang</option>
                                                          </select>
                                                </div>
                                                <div class="col-12 mt-1" v-if="iscash2==2">
                                                    <select id="inputCabang" v-model="bankDebit" class="form-select form-control pt-1">
                                                        <option value="">Pilih Bank</option>
                                                        <option value="Bank BNI">Bank BNI</option>
                                                        <option value="Bank BRI">Bank BRI</option>
                                                        <option value="Bank BCA">Bank BCA</option>
                                                        <option value="Bank BCA">Bank MANDIRI</option>
                                                    </select>
                                                    
                                                </div>
                                                <div class="col-12 mt-1" v-if="iscash2==2">
                                                    <input type="text" v-model="jenisDebit" class="form-control" id="norekInput" placeholder="Jenis Debit (EDC/TF)">
                                                </div>
                                            </div>
                                       
                                    </div>

                                    <!-- <h4 class=" ">
                                        {{ iscash ? 'Cash' : 'Hutang' }}
                                    </h4> -->
                                </div>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nama Produk</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col" >Diskon(%)</th>
                                        <th scope="col">Harga</th>
                                        <th scope="col">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(post, index) in keranjang" :key="index">
                                        <th scope="row">{{ index+1 }}</th>
                                        <td>{{post.barang.nama_barang}}</td>
                                        <td style="font-size: 18px">
                                            <!-- <i class='bx bxs-minus-circle ' @click="minQty(index)"></i>
                                            {{quantity[index] }}
                                            <i class='bx bxs-plus-circle ' @click="plusQty(index)"></i> -->
                                        <input type="number" min="1" v-model="quantity[index]" @change="plusQty(index)" class="form-control">
                                        </td>
                                        <td><input min="0" max="100" type="number" v-model="diskonarray2[index]" @change="diskonFunction2(index)" class="form-control" ></td>
                                        <td>{{'Rp'+cekharga(post,index).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}}</td>
                                        <td>
                                            <button class="btn btn-danger text-white" @click="hapus(post,index)"><i class='bx bxs-trash-alt'></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mb-3 row">
                                <label for="totalbayar" class="col-sm-2 col-form-label">Total Bayar </label>
                                <div class="col-sm-10">
                                    <input type="text" id='totalbayar' class="form-control mb-1 mt-1" :value="'Rp.'+cektotalkeranjang().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')" readonly>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="totalbayar" class="col-sm-2 col-form-label">Potongan </label>
                                <div class="col-sm-10">
                                    <div class="input-group ">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                        <input v-model="potongan1hitung" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Potongan" @change="savednewpotongan1" @input="savednewpotongan1">

                                    </div>
                                </div>
                            </div>
                            <div v-if="iscash2==2" class="mb-3 row">
                                <label for="nominalbayar" class="col-sm-2 col-form-label">Nominal EDC/TF </label>
                                <div class="col-sm-10">
                                    
                                    <div class="input-group ">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                        <input v-model="edcnominal2" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Nominal EDC" @change="savednew20" @input="savednew2">
                                    </div>
                                    <input type="text" id="nominalbayar" class="form-control mb-1 d-none" placeholder="0" v-model="edcnominal" @input="savednew2">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="nominalbayar" class="col-sm-2 col-form-label">Nominal Bayar </label>
                                <div class="col-sm-10">
                                    <div class="input-group ">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> Rp </button>
                                        <input v-model="tempnominal" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Nominal" :disabled="iscash2==2? true : false" @change="savednew0" @input="savednew">
                                    </div>
                                    <!-- <input type="text" id="nominalbayar" class="form-control mb-1 d-none" placeholder="0" v-model="tempnominal" @input="savednew"> -->
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label v-if="iscash2" for="kembaliannominal" class="col-sm-2 col-form-label">Kembalian</label>
                                <label v-else for="kembaliannominal" class="col-sm-2 col-form-label">Kurang </label>
                                <div class="col-sm-10">
                                    <input type="text" id="kembaliannominal" class="form-control mb-1" :value="'Rp.'+((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')" readonly>
                                </div>
                            </div>

                            <div class="d-flex justify-content-end">
                                  <!-- <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout1" :disabled="iscash==1 ?((parseInt(nominalpembayaran))-cektotal()) < 0 || isNaN((parseInt(nominalpembayaran))-cektotal())?  1 : 0 : iscash==2 ? ((parseInt(nominalpembayaran)+parseInt(edcnominal))-cektotal()) < 0 || isNaN((parseInt(nominalpembayaran)+parseInt(edcnominal))-cektotal())?  1 : 0 :((parseInt(nominalpembayaran)+parseInt(edcnominal))-cektotal()) > 0 ? 1 : 0">Checkout</button> -->
                                <!-- <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout" :disabled="iscash==1 || iscash==2 ? ((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang()) < 0 ?  isNaN((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang()) ? 0:1 : 0 :((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang()) > -1 ? 1 : 0">Checkout</button> -->
                                <button v-if="iscash" class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout" :disabled=" ((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang())>-1 ?0:1">Checkout</button>
                                <button v-else class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#staticCheckout" :disabled="((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang())>0 ?1:0">Checkout</button>
                                <!-- Modal -->
                                <div class="modal fade" id="staticCheckout" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">

                                            <div class="modal-body">

                                                <div class="text-center">
                                                    <img :src="'/logo/tanya.png'" alt="Logo" style="width:50px;height:50px">
                                                </div>
                                                <br>
                                                Apakah Anda Yakin Ingin Checkout?
                                                <br>
                                                Nama Pelanggan : <a :class="namapembeli == '' ? 'text-danger' : ''">{{ namapembeli == '' ? 'Belum Ada' : namapembeli }}</a>
                                                <br>
                                                Total Harga : {{ 'Rp.'+cektotalkeranjang().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')}}
                                                <br>
                                                Jenis Pembayaran : {{ iscash == 1? 'Cash' : iscash == 2? 'Debit ': 'Hutang' }}
                                                <br>
                                                Bayar : {{ nominalpembayaran }}
                                                <br>
                                                Potongan {{ potongan1 }}
                                                <br>
                                                <div v-if="iscash">
                                                    Kembalian : {{ 'Rp.'+((parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))-cektotalkeranjang()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')}}
                                                </div>
                                                <div v-else>
                                                    Sisa Hutang : {{ 'Rp.'+(cektotalkeranjang()-(parseInt(nominalpembayaran)+parseInt(edcnominal)+parseInt(potongan1))).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1\.')}}
                                                </div>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Nama Produk</th>
                                                            <th scope="col">Qty</th>
                                                            <th scope="col">Harga</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="keranjang.length >0">
                                                        <tr v-for="(post, index) in keranjang" :key="index">
                                                            <th scope="row">{{ index+1 }}</th>
                                                            <td>{{post.barang.nama_barang}}</td>
                                                            <td style="font-size: 18px">
                                                                {{quantity[index] }}
                                                            </td>
                                                            <td>{{'Rp'+cekharga(post,index).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <h4 class="text-center">
                                                                    Keranjang Kosong
                                                                </h4>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br>

                                                <div class="text-end">
                                                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Tidak</button>
                                                    <button type="button" class="btn btn-primary text-white" @click="checkouts2" data-bs-dismiss="modal">Ya</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Button trigger modal -->
                                <button type="button" class="btn btn-primary notifberhasil" data-bs-toggle="modal" data-bs-target="#staticSuccess" style="display:none">

                                </button>

                                <!-- Modal -->
                                <div class="modal fade" id="staticSuccess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">

                                            <div class="modal-body">
                                                <div class="text-center">
                                                    <h3> Transaksi Penjualan Berhasil</h3>
                                                    <br>
                                                    <img :src="'/logo/checked.png'" alt="Logo" style="width:50px;height:50px">
                                                    <br>
                                                    <br>
                                                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Kembali ke Dashboard</button>
                                                    <button type="button" class="btn btn-primary text-white" @click="checkouts2print" data-bs-dismiss="modal">Print Transaksi</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
document.addEventListener("keypress", function (e) {
    if (e.target.tagName !== "INPUT") {
        var input = document.querySelector(".my-input");
        input.focus();
        input.value = e.key;
        e.preventDefault();
    }
});
import axios from 'axios'

export default {
    name: 'Dashboard',
    data() {
        return {
            //state loggedIn with localStorage
            loggedIn: localStorage.getItem('loggedIn'),
            //state token
            token: localStorage.getItem('token'),
            kantorcabangid: '',
            //state user logged In
            user: [],

            search: '',
            page: 1,
            cabang: [],
            tanggal1: [],
            bulan1: [],
            tahun1: [],
            tanggal2: [],
            bulan2: [],
            tahun2: [],
            karyawan: [],
            invoice: '',
            show: [],
            jumtransaksi: 0,
            jumpendapatan: 0,
            cabang1: '',
            cabang2: localStorage.getItem('cabang'),
            cabang3: '',
            pilihIndex1: '',
            pilihIndex2: '',
            dataInv: [],
            chartOptions: {},
            series: [],
            chartOptions2: {},
            series2: [],
            chartOptions3: {},
            series3: [],
            nominal: 0,
            nominalEDC: 0,
            link: this.globalApiUrl,
            loading: true,
            loadingbuka: true,
            tokobuka: false,
            tokoidbuka: '',

            posts2: {},
            page2: 1,
            pagination2: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            inv2: '',
            userid2: '',
            search2: '',
            edit2: [],
            dashboards: true,

            postbarangs: {},
            pagebarang: 1,
            paginationbarang: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            searchbarang: '',
            searchCabang: '',
            cabang: [],
            supplier: [],
            kategori: [],
            keranjang: [],
            quantity: [],
            stokkeranjang: [],
            iscash: 1,
            iscash2: 1,
            bankDebit:'',
            jenisDebit: '',
            bankDebit2:'',
            jenisDebit2: '',
            namapembeli: '',
            nominalpembayaran: 0,
            nominalpembayaran2: 0,
            tempInv: '',
            tempnominal: 0,
            edcnominal: 0,
            edcnominal2:0,
            metode_pembayaran: '',
            diskonarray:[],
            diskonarray2:[],
            potongan:0,
            potongan1:0,
            potongan1hitung:0,
            potonganhitung:0,
        }
    },
    created() {
        // this.$forceUpdate();
        // this.loading = true;
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                return this.$router.push({
                    name: 'login.index'
                })
            }
        } else {
            console.log(this.token);
            this.getResults2();
            // this.getCabang();
            
            axios.get(this.link + 'api/gettotal?cabang=' + this.cabang2, {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.jumtransaksi = response.data.data.transaksi;
                this.jumpendapatan = response.data.data.pendapatan;
            });
            axios.get(this.link + 'api/getaktif', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.tokoidbuka = response.data.data.id;
                this.tokobuka = response.data.data.aktif == 1 ? true : false;
            });

       
            axios.get(this.link + 'api/user', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.user = response.data.data.user
                this.loading = false;
                this.loadingbuka = false;
            })

        }

    },
    methods: {
        diskonFunction2(index){
            this.diskonarray2[index] = this.diskonarray2[index]>100 ? 100 : this.diskonarray2[index];
        },
        diskonFunction(index){
            this.diskonarray[index] = this.diskonarray[index]>100 ? 100 : this.diskonarray[index];
            console.log(((100-this.diskonarray[index])/100))
            this.edit2.detail_transaksi[index].harga_fix2 = this.edit2.detail_transaksi[index].harga_fix*((100-this.diskonarray[index])/100);
            console.log( this.edit2.detail_transaksi[index].harga_fix2 );
        },
        nominalrupiah2(){
            //  this.nominalpembayaran = this.tempnominal == '' ? 0 : this.tempnominal.toString().split(".").join('');
            this.nominal = this.nominal.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
        },
        nominalrupiah(){
            //  this.nominalpembayaran = this.tempnominal == '' ? 0 : this.tempnominal.toString().split(".").join('');
            this.nominalEDC = this.nominalEDC.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
            if(this.metode_pembayaran==2){
                console.log('f');
                var x =parseInt(this.cektotal())- parseInt(this.nominalEDC.toString().split(".").join('')) -this.potongan;
                if(x<0){
                    this.nominalEDC =parseInt(this.cektotal())-this.potongan1 == '' ? '' : parseInt(this.cektotal())-this.potongan;
                    this.nominal=0;
                     this.nominal=this.nominal.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
                }else{
                    this.nominal =x ;
                    this.nominal=this.nominal.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
                    
                }
            }
        },
        savednew0() {
            this.nominalpembayaran = this.tempnominal == '' ? 0 : this.tempnominal.toString().split(".").join('');
            this.tempnominal = this.tempnominal.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
        },
        savednew() {
            this.nominalpembayaran = this.tempnominal == '' ? 0 : this.tempnominal.toString().split(".").join('');
            this.tempnominal = this.tempnominal.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
        },
        savednewpotongan1(){
             this.potongan1 = this.potongan1hitung == '' ? 0 : this.potongan1hitung.toString().split(".").join('');

            this.potongan1hitung = this.potongan1hitung.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
        },
        savednewpotongan(){
             this.potongan = this.potonganhitung == '' ? 0 : this.potonganhitung.toString().split(".").join('');

            this.potonganhitung = this.potonganhitung.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
        },
        savednew20(){
             this.edcnominal = this.edcnominal2 == '' ? 0 : this.edcnominal2.toString().split(".").join('');

            this.edcnominal2 =this.edcnominal2 == '' ? 0 : this.edcnominal2.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
            
        },
        savednew2() {
            this.edcnominal = this.edcnominal2 == '' ? '' : this.edcnominal2.toString().split(".").join('');

            this.edcnominal2 =this.edcnominal2 == '' ? '' : this.edcnominal2.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
            if(this.iscash==2){
                console.log('f');
                var x =parseInt(this.cektotalkeranjang())- parseInt(this.edcnominal) -this.potongan1;
                if(x<0){
                    this.edcnominal = parseInt(this.cektotalkeranjang())-this.potongan1 == '' ? '' : parseInt(this.cektotalkeranjang())-this.potongan1.toString().split(".").join('');

                    this.edcnominal2 =parseInt(this.cektotalkeranjang())-this.potongan1 == '' ? '' : parseInt(this.cektotalkeranjang())-this.potongan1.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
                    this.nominalpembayaran=0;
                     this.tempnominal=this.nominalpembayaran.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
                }else{
                    this.nominalpembayaran =parseInt(this.cektotalkeranjang())- parseInt(this.edcnominal) -this.potongan1;
                    this.tempnominal=this.nominalpembayaran.toString().split(".").join('').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.");
                    
                }
            }
        },
        
        cektotalkeranjang() {
            var totale = 0;
            for (var i = 0; i < this.keranjang.length; i++) {
                var diskons = this.keranjang[i].barang.diskon;
                var k = 0;
                // console.log(this.iscash);
                // if (this.keranjang[i].barang.tipe_harga.length > 0) {
                //     this.keranjang[i].barang.tipe_harga.forEach(z => {
                //         if (z.min_jumlah <= this.quantity[i]) {
                //             if (this.iscash) {
                //                 k =  this.keranjang[i].barang.harga_cash ;
                //             } else {
                //                 k =  this.keranjang[i].barang.harga_hutang ;
                //             }
                //         }
                //     });
                //     if (k == 0) {
                //         if (this.iscash) {
                //             k = parseInt(((100-this.diskonarray2[i])/100)  * this.keranjang[i].barang.harga_cash);
                //         } else {
                //             k = parseInt(((100-this.diskonarray2[i])/100)  * this.keranjang[i].barang.harga_hutang);
                //         }
                //     }
                //     totale += k * this.quantity[i];
                // } else {
                    if (this.iscash) {
                        totale += parseInt(((100-this.diskonarray2[i])/100)  * this.keranjang[i].barang.harga_cash) * this.quantity[i];
                    } else {
                        totale += parseInt(((100-this.diskonarray2[i])/100)  * this.keranjang[i].barang.harga_hutang) * this.quantity[i];
                    }
                // }
            }
            return totale;
        },
        // checkouts() {
        //     var form = new FormData();
        //     console.log(this.quantity);
        //     form.append('name', this.namapembeli);
        //     form.append('iscash', this.iscash ? 1 : 0);
        //     form.append('qty', JSON.stringify(this.quantity));

        //     axios.post(this.link + 'api/checkout', form, {
        //         headers: {
        //             'Authorization': this.token
        //         }
        //     }).then(res => {
        //         // this.tambahSuccess = true;
        //         this.$toast.show(`Data Berhasil Dicheckout`, {
        //             position: "top",
        //             duration: 1000,
        //             max: 1,
        //         });
        //         var form1 = new FormData();
        //         form1.append('inv', res.data.data.data);
        //         form1.append('bayar', this.nominalpembayaran);
        //         axios.post(this.link + 'api/transaksi-update', form1, {
        //             headers: {
        //                 'Authorization': this.token
        //             }
        //         }).then(res1 => {
        //             this.$nextTick(() => {
        //                 // this.$refs.questionInput.focus();
        //                 document.querySelector(".my-nominal").blur();
        //             })
        //             this.nominalpembayaran = '';
        //             this.quantity = [];
        //             this.stokkeranjang = [];
        //             this.keranjang = [];
        //             // this.feshkeranjang();
        //             // this.invoice = '';
        //             let routeData = this.$router.resolve({
        //                 name: 'print.index',
        //                 query: {
        //                     data: res.data.data.data
        //                 }
        //             });
        //             window.open(routeData.href, '_blank');
        //         }).catch(error => {

        //             console.log(error);
        //         })

        //     }).catch(error => {
        //         this.$toast.show(error.response.data.data.message, {
        //             type: 'error',
        //             position: "top",
        //             duration: 1000,
        //             max: 1,
        //         });
        //     })
        // },
        checkouts2() {
            var form = new FormData();
            // console.log(JSON.stringify(this.diskonarray2));
            console.log(this.quantity);

            form.append('name', this.namapembeli);
            form.append('iscash', this.iscash ==1 ? 1 : this.iscash ==2? 2 : 0);
            form.append('diskon', JSON.stringify(this.diskonarray2));
            form.append('qty', JSON.stringify(this.quantity));
            

            axios.post(this.link + 'api/checkout', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Dicheckout`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                var form1 = new FormData();
                form1.append('inv', res.data.data.data);
                form1.append('ischange',1);
                form1.append('diskon', JSON.stringify(this.diskonarray2));
                form1.append('bayar', this.nominalpembayaran);
                form1.append('bayar2', this.edcnominal);
                 form1.append('iscash', this.iscash2 ==1 ? 1 : this.iscash2 ==2? 2 : 0);
                form1.append('bankDebit',  this.iscash ==2? this.bankDebit : '');
                form1.append('jenisDebit',  this.iscash ==2? this.jenisDebit : '');
                form1.append('potongan',this.potongan1);
                axios.post(this.link + 'api/transaksi-update', form1, {
                    headers: {
                        'Authorization': this.token
                    }
                }).then(res1 => {
                    this.$nextTick(() => {
                        // this.$refs.questionInput.focus();
                        // document.querySelector(".my-nominal").blur();
                        document.querySelector(".notifberhasil").click();
                    })
                    this.nominalpembayaran = 0;
                    this.quantity = [];
                    this.stokkeranjang = [];
                    this.keranjang = [];
                    this.diskonarray2 = [];
                    this.tempnominal = 0;
                    this.edcnominal = 0;
                    this.edcnominal2=0;
                    this.bankDebit = '';
                    this.jenisDebit = '';
                    this.iscash2 = 1;
                    this.iscash = 1;
                    this.quantity = [];
                    // this.name = '';
                    this.potongan1=0;
                    this.potongan1hitung=0;
                    // this.feshkeranjang();
                    // this.invoice = '';
                    this.tempInv = res.data.data.data;

                }).catch(error => {

                    console.log(error);
                })

            }).catch(error => {
                this.$toast.show(error.response.data.data.message, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        checkouts2print() {
            let routeData = this.$router.resolve({
                name: 'print.index',
                query: {
                    data: this.tempInv
                }
            });
            window.open(routeData.href, '_blank');
        },
        onofcash() {
            this.iscash = this.iscash2==1 ? 1 : this.iscash2==2  ? 2 : 0;
            this.edcnominal= this.iscash2==2  ? this.edcnominal : 0;
            this.edcnominal2 =this.iscash2==2  ? this.edcnominal2 : 0;
            this.feshkeranjang2();
        },
        hapus(a, b) {
            var form = new FormData();
            form.append('cart', a.id);

            axios.post(this.link + 'api/keranjang-delete', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Dihapus`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.quantity.splice(b, 1);
                this.diskonarray2.splice(b, 1);
                this.stokkeranjang.splice(b, 1);
                this.feshkeranjang(a.barang.id);
            }).catch(error => {
                this.$toast.show(error.response.data.data.message, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        tambahkeranjang(a) {
            var form = new FormData();
            form.append('barang', a);
            form.append('qty', 1);

            axios.post(this.link + 'api/keranjang', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.feshkeranjang(a);
            }).catch(error => {
                this.$toast.show(error.response.data.data.error, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        // minQty(index) {
        //     if (this.quantity[index] > 1) {
        //         this.quantity[index] = this.quantity[index] - 1;
        //     }
        // },
        plusQty(index) {
            if(this.quantity[index]==0){
                this.quantity[index] = 1;
            }else{
                if (this.quantity[index] < this.stokkeranjang[index]) {
                    this.quantity[index] = this.quantity[index];
                }else{
                    this.quantity[index] = this.stokkeranjang[index];
                }
            }
        },
        cekharga(a, b) {
            var diskons = a.barang.diskon;
            var k = 0;
            console.log(a);
            // if (a.barang.tipe_harga.length > 0) {
            //     a.barang.tipe_harga.forEach(i => {
            //         if (i.min_jumlah <= this.quantity[b]) {
            //             if (this.iscash) {
            //                 k =  a.barang.harga_cash ;
            //             } else {
            //                 k = a.barang.harga_hutang ;
            //             }
            //         }
            //     });
            //     // if (k == 0) {
            //     //     if (this.iscash) {
            //     //         k = (((100 - diskons) / 100) * a.barang.harga_cash);
            //     //     } else {
            //     //         k = (((100 - diskons) / 100) * a.barang.harga_hutang);
            //     //     }
            //     // }
            //     return k *((100-this.diskonarray2[b])/100) * this.quantity[b];
            // } else {
                if (this.iscash) {
                    return parseInt(((100-this.diskonarray2[b])/100) * a.barang.harga_cash) * this.quantity[b];
                } else {
                    return parseInt(((100-this.diskonarray2[b])/100) * a.barang.harga_hutang) * this.quantity[b];
                }
            // }

        },
        clearedit() {
            this.edit2 = []
        },
        selcetmenu() {
            this.dashboards = true;
            this.loading = true;
            this.getResults2();
            // this.getCabang();
            
            axios.get(this.link + 'api/gettotal?cabang=' + this.cabang2, {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.jumtransaksi = response.data.data.transaksi;
                this.jumpendapatan = response.data.data.pendapatan;
            });
            axios.get(this.link + 'api/getaktif', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.tokoidbuka = response.data.data.id;
                this.tokobuka = response.data.data.aktif == 1 ? true : false;
            });

           
            axios.get(this.link + 'api/user', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.user = response.data.data.user
                this.loading = false;
                this.loadingbuka = false;
            })
        },
        selcetmenu2() {
            this.dashboards = false;
            this.loading = true;
            this.keranjang = [];
            this.quantity = [];
            this.stokkeranjang = [];
            this.postbarangs = {};
            this.paginationbarang = {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            };
            this.pagebarang = 1;

            this.getBarangbaru();
            axios.get(this.link + 'api/keranjang', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                var a = 0;
                this.keranjang = response.data.data

                this.keranjang.forEach(i => {
                    this.quantity[a] = i.qty;
                    this.diskonarray2[a] = 0;
                    this.stokkeranjang[a] = i.barang.stok;
                    a = a + 1;
                });
                console.log(this.stokkeranjang);
            });
        },
        feshkeranjang(b) {
            axios.get(this.link + 'api/keranjang', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                var a = 0;
                this.keranjang = response.data.data

                this.keranjang.forEach(i => {
                    // this.keranjang.filter((x) => {
                    //      x.barang.id.match(b);
                    //     //  console.log(x.barang.id.match(b));
                    // });
                    if (this.quantity[a] == null) {
                        this.quantity[a] = i.qty;
                        this.diskonarray2[a] = 0;
                        this.stokkeranjang[a] = i.barang.stok;
                    } else if (i.barang.id == b) {
                        this.quantity[a] = i.qty;
                        this.stokkeranjang[a] = i.barang.stok;
                    } else {

                    }

                    a = a + 1;
                });
                console.log(this.stokkeranjang);
            });

        },
        feshkeranjang2() {
            axios.get(this.link + 'api/keranjang', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                var a = 0;
                this.keranjang = response.data.data

                this.keranjang.forEach(i => {
                    if (this.quantity[a] == null) {
                        this.quantity[a] = i.qty;
                        this.stokkeranjang[a] = i.barang.stok;
                    }

                    a = a + 1;
                });
                console.log(this.stokkeranjang);
            });

        },
        getBarangbaru() {
            axios.get(this.link + 'api/barang?limit=10&name=' + this.searchbarang + '&page=' + this.pagebarang + '&cabang=' + this.cabang2)
                .then(response => {

                    this.postbarangs = response.data.data.data;
                    this.paginationbarang = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    this.loading = false
                );
        },
        // getCabang() {
        //     axios.get(this.link + 'api/cabang', {
        //         headers: {
        //             'Authorization': this.token
        //         }
        //     }).then(response => {
        //         console.log(response)
        //         this.cabang = response.data.data // assign response to state user
        //     })
        // },
        getKategori() {
            axios.get(this.link + 'api/kategori', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.kategori = response.data.data // assign response to state user
            })
        },

        getSupplier() {
            axios.get(this.link + 'api/supplier-semua', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.supplier = response.data.data // assign response to state user
            })
        },
        getResetbarang() {
            if (this.searchbarang != '') {
                this.searchbarang = "";
                this.pagebarang = 1;
                this.getBarangbaru();
            }
        },
        onClickbarang(item) {
            this.pagebarang = item;
            this.getBarangbaru();
        },
        getSearchbarang() {
            this.pagebarang = 1;
            this.getBarangbaru();
        },
        onClickbarangs(item) {
            this.loading = true;

            axios.get(item + '&limit=10&name=' + this.searchbarang + '&cabang=' + this.searchCabang)
                .then(response => {
                    this.pagebarang = response.data.data.current_page;
                    this.postbarangs = response.data.data.data;
                    this.paginationbarang = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                });
            this.loading = false;
        },
        getResults2() {
            // this.loading = true;
            // console.log(this.loading);
            axios.get(this.link + 'api/transaksipending?limit=8&inv=' + this.invoice + '&cabang=' + this.cabang2 + '&userid=' + this.userid2 + '&page=' + this.page2, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    console.log(response.data.data.data)
                    this.posts2 = response.data.data.data;
                    this.pagination2 = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                    if (this.invoice != '') {
                        if (this.posts2.length == 1) {
                            this.setEdit2(0)
                        }
                    }
                });

            // console.log(this.loading);
        },

        onClick2(a) {
            this.page2 = a;
            this.getResults2();
        },
        onClicks2(a) {
            console.log(this.loading);
            axios.get(a + '&limit=&inv=' + this.search2 + '&cabang=' + this.cabang2 + '&userid=' + this.userid2, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    console.log(response.data.data.data)
                    this.posts2 = response.data.data.data;
                    this.pagination2 = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    // this.loading = false
                );
        },
        total2(a) {
            var harga_fix = 0;

            this.posts2[a].detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix*((100-i.diskon)/100) * i.qty)
            });
            return harga_fix;
        },
        setEdit2(a) {
            this.edit2 = this.posts2[a];
            this.metode_pembayaran=this.edit2.iscash;
            console.log(this.edit2.detail_transaksi.length);
            for (let i = 0; i < this.edit2.detail_transaksi.length; i++) {
                this.edit2.detail_transaksi[i].harga_fix2=this.edit2.detail_transaksi[i].harga_fix*((100-this.edit2.detail_transaksi[i].diskon)/100);
                console.log(this.edit2.detail_transaksi[i].diskon);
                this.diskonarray[i]=this.edit2.detail_transaksi[i].diskon;
            }
            this.$nextTick(() => {
                // this.$refs.questionInput.focus();
                document.querySelector(".my-nominal").focus();
            })
            console.log(this.edit2);
        },
        
        detailshow(p) {
            this.show = p;
        },
        ctkfile() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            var form = new FormData();
            console.log(this.edit2.invoice);
            form.append('inv', this.edit2.invoice);
            form.append('diskon', JSON.stringify(this.diskonarray));
            form.append('bayar', this.nominal.toString().split(".").join(''));
            form.append('bayar2', this.nominalEDC.toString().split(".").join(''));
            form.append('iscash', this.metode_pembayaran);
            form.append('bankDebit',  this.metode_pembayaran ==2? this.bankDebit2 : '');
            form.append('jenisDebit',  this.metode_pembayaran ==2? this.jenisDebit2 : '');
            form.append('potongan', this.potongan);
            axios.post(this.link + 'api/transaksi-update', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                this.$nextTick(() => {
                    // this.$refs.questionInput.focus();
                    document.querySelector(".my-nominal").blur();
                        document.querySelector(".notifberhasil1").click();
                  
                })
                this.potongan=0;
                this.potonganhitung=0;
                this.nominal = 0;
                this.search2 = '';
                this.invoice = '';
                this.metode_pembayaran = '';
                this.nominalEDC = 0;
                this.bankDebit2 = '';
                this.jenisDebit2 = '';
                this.diskonarray = [];

                this.getResults2();
                // this.invoice = '';
               
            }).catch(error => {

                console.log(error);
            })
        },
        printnewfile(p){
             if(p==true){
                 let routeData = this.$router.resolve({
                    name: 'print.index',
                    query: {
                        data: this.edit2.invoice
                    }
                });
                this.edit2 = [];
                window.open(routeData.href, '_blank');
             }else{
                 this.edit2 = [];
             }
        },
        kembalian() {
            if(this.metode_pembayaran==2){

                return parseInt(this.nominal  )+ parseInt(this.nominalEDC)- this.cektotal();
            }else{
                if(this.keranjang.length==0){
                    return 0;
                }
                return parseInt(this.nominal )- this.cektotal();
            }
        },
        cekinv() {
            var input = document.querySelector(".my-input");
            var valuess_inv = input.value;
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            var form = new FormData();
            // form.append('inv', valuess_inv);
            axios.get(this.link + 'api/transaksi-cek/' + valuess_inv, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                this.dataInv = res.data.data;
                // input.blur();
                //  var input2 = document.querySelector(".my-input2");
                this.$nextTick(() => {
                    // this.$refs.questionInput.focus();
                    document.querySelector(".my-nominal").focus();
                })

                this.invoice = '';

            }).catch(error => {

                console.log('ERROR');
            })
        },
        prints() {
            var input = document.querySelector(".my-input");
            var valuess_inv = input.value;
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            var form = new FormData();
            form.append('inv', valuess_inv);
            axios.post(this.link + 'api/transaksi-update', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                input.blur();
                this.invoice = '';
                let routeData = this.$router.resolve({
                    name: 'print.index',
                    query: {
                        data: valuess_inv
                    }
                });

                window.open(routeData.href, '_blank');
            }).catch(error => {

                console.log('ERROR');
            })

        },
        cektotal() {
            var harga_fix = 0;

            this.edit2.detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix2 * i.qty)
            });
            return parseInt(harga_fix);
        },
        cek() {
            console.log(this.invoice);
            var input = document.querySelector(".my-input");
            input.blur();
            this.invoice = '';
        },
        logout() {
            axios.get(this.link + 'api/logout')
                .then(() => {
                    //remove localStorage
                    localStorage.removeItem("loggedIn")
                    localStorage.removeItem("token")

                    //redirect
                    return this.$router.push({
                        name: 'login'
                    })
                })
        },
        onClick(item) {

            this.page = item;
        },
        // getCabang() {
        //     axios.get(this.link + 'api/cabang', {
        //             headers: {
        //                 'Authorization': this.token
        //             }
        //         })
        //         .then(response => {
        //             this.cabang = response.data.data;
        //             this.cabang1 = this.cabang[0].id;
        //             this.cabang2 = this.cabang[0].id;
        //             this.cabang3 = this.cabang[0].id;
        //             this.pilihIndex1 = 0;
        //             this.pilihIndex2 = 0;
        //         });
        // },

        getSearch() {},
        onClicks(item) {
            axios.get(item + '&limit=5&inv=' + this.search, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    this.page = response.data.data.current_page;
                    this.transaksipending = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                });
        },
      

    },

    //check user logged in or not
    // mounted() {
    //     if (!this.loggedIn) {
    //         if(!this.token){
    //             return this.$router.push({
    //                 name: 'login.index'
    //             })
    //         }else{
    //             localStorage.removeItem("loggedIn");
    //             localStorage.removeItem("token");
    //         }
    //     }
    // },

}
</script>

<style>
.colordashboard {
    color: #747987;
}

.shadowcard {
    box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
    -webkit-box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
    -moz-box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
}

.tombol-disable {
    color: #15a362;
    border: 1px solid #15a362;

}
</style>
